import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./routes";
import posthog from "posthog-js";
import "./assets/css/icofont.min.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import "./component/CookieBanner";
import CookiesBanner from "./component/CookieBanner";

export default function App() {
  return (
    <Router>
      <Routing />
      {posthog.has_opted_in_capturing() ||
      posthog.has_opted_out_capturing() ? null : (
        <CookiesBanner />
      )}
    </Router>
  );
}
