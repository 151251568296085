import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import posthog from "posthog-js";

posthog.init("phc_BnaBIfO5SoMToyTqojWtoR9rlhbyYZiSEQGu14xc1vB", {
  api_host: "https://app.posthog.com",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
