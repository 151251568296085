import posthog from "posthog-js";
import { useState } from "react";

function CookiesBanner() {
  const [showCookies, setShowCookies] = useState(true);

  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowCookies(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowCookies(false);
  };

  return (
    <div>
      {showCookies && (
        <div className="cookie-banner">
          <p>
            We use cookies on this site to enhance your experience. By
            continuing to use this website you consent to our use of cookies.
            For more information, please see our <b>Cookie Policy</b>
          </p>
          <button className="accept-cookies" onClick={acceptCookies} type="">
            Accept
          </button>
          <button type="" onClick={declineCookies}>
            Decline
          </button>
        </div>
      )}
    </div>
  );
}

export default CookiesBanner;
